import * as React from "react"
import type { HeadFC } from "gatsby"
import Seo from "../../components/Seo"
import Layout from "../../components/Layout"
import { SectionWrapper, Node } from "../../components/Sections"
import { HeadingTitle } from "../../components/styling/Heading"
import BorderWrapper from "../../components/styling/BorderWrapper"
import { StaticImage } from "gatsby-plugin-image"
import Zoom from "react-medium-image-zoom"

const title = "A Witchy Demo"

const headings = [
  { title: "Wireframes", id: "Wireframes" },
  { title: "Final Product", id: "Final-Product" },
]

const summmary: Summary = {
  headings: headings,
  details: ["Mobile Game", "2023 (iOS and Android Release)"],
  role: [
    "UI / UX Designer",
    "UI Programmer",
    "Game Designer",
    "Game Programmer",
  ],
  tools: ["Figma", "Affinity", "Unity", "C#"],
  extraContent: (
    <BorderWrapper style={{ marginBottom: "20px" }}>
      <Zoom>
        <StaticImage
          src="../../images/wiw/WitchTitle.png"
          alt="The rather minimal start screen to Which is Witch. On the right there is 18th century cartoonish-looking artwork depicting a skeleton with a lyre grabinng a minstrals hand and pulling them forward. On the left there is the title of the game, Which is Witch, two underlined menu options of New Game and Exit Game, and the credits that say: A game by Megan Carnes, Cregg Hancock, and Zach Berglund."
        />
      </Zoom>
    </BorderWrapper>
  ),
}

const WiwPage = () => {
  return (
    <>
      <Layout>
        <SectionWrapper
          as="article"
          heading={
            <HeadingTitle
              name={title}
              subtitle="/overviews/Which-is-Witch"
              summary={summmary}
            />
          }
        >
          <Node.p>
            If the game equivalent of a music single is a game jam, and a
            full-length LP is an entire game release, then Which Is Witch is an
            EP. Which is Witch was initially made for a game jam competition
            centered around integrating Dolby Sound for mobile games. I was
            lucky enough to be the UX implementor for the winning team that was
            granted an additional funding-prize to continue polishing the game
            into a released demo!
          </Node.p>
          <Node.p style={{ marginTop: "10px" }}>
            This second stretch of development gave us the opportunity to more
            thoroughly plan out details than is typically possible in a gam jam.
            On my end, I used the time to focus on how we could accommodate
            players picking up the game from any point a previous player had
            left it. In other words, a demo phone could be in the middle of
            gameplay and we wanted to make sure a new player could pick up that
            phone, figure out what was going on, and then restart the game if
            they wanted to. In light of that, here's how I mocked up the flow of
            the game along with some screenshots of the finished product after:
          </Node.p>
          <BorderWrapper style={{ marginTop: "20px" }}>
            <Zoom>
              <StaticImage
                src="../../images/wiw/WiWWorkflow.png"
                alt="A workflow chart of Which is Witch mockups. The workflow begins from the Start Screen, then if it's a new game that has been started it leads to the Intro and Help (New Game) screen before moving on to the main gameplay scene, but if it isn't a new game it goes directly to gameplay. There are three menu icons from gameplay that can lead to the Map / Town Selection screen, the Pause Menu, and the Intro and Help (Game In-Progress) screen. The relevant option  in the Pause Menu gives the player the opportunity to reset the game, which then leads it to a reset confirmation popup that, if a reset is confirmed, leads to the Intro and Help (New Game) screen. The Intro and Help (Game In-Progress) screen follows the same process as that Pause menu option. The last option from the gameplay was the Map / Town Selection screen that gives the player mutiple buttons that represent each of the towns. Selecting any of the buttons leads the player to the Town Arrival Alert which is the same as the Gameplay screen but with an additional allert that fades out over time."
              />
            </Zoom>
          </BorderWrapper>
          <BorderWrapper style={{ marginTop: "20px" }}>
            <Zoom>
              <StaticImage
                src="../../images/wiw/Witch1.jpg"
                alt="A screenshot from Which is Witch depicting the player and various villagers standing around a farm with a large church in the distance. All of this is displayed as if paper cutouts of were standing upright in 3-D space. The UI surrounds the gameplay and currently has the lyrics: to protect us from darkness."
              />
            </Zoom>
          </BorderWrapper>
          <Node.multiCol
            style={{ marginTop: "20px" }}
            col1={
              <BorderWrapper>
                <Zoom>
                  <StaticImage
                    src="../../images/wiw/Witch2.jpg"
                    alt="A screenshot from Which is Witch depicting the player in the middle of accusing a viller. A popup on the bottom of the screen asks the player: Doth thee acuse this villager of witchery? With these two options to respond displayed below: Accuse the Witch. No, Keep Hunting."
                  />
                </Zoom>
              </BorderWrapper>
            }
            col2={
              <BorderWrapper>
                <Zoom>
                  <StaticImage
                    src="../../images/wiw/Witch3.jpg"
                    alt="A screenshot from Which is Witch depicting a successful accusation of a witch. Large text is displayed on the screen that says 'They were a Witch' and a witch can be seen floating in paper-looking water behind the text."
                  />
                </Zoom>
              </BorderWrapper>
            }
          />
          <BorderWrapper style={{ marginTop: "10px" }}>
            <Zoom>
              <StaticImage
                src="../../images/wiw/Witch4.png"
                alt="A screenshot from Which is Witch depicting the map screen. Large text is displayed in the top left corner that says: Select a Village. Three button options sit below with the text of Vestraboro, Valen, and Slelcoth. On the right side of the screen is a very rudimentary map depicting small towns for each of these three villages as well as a locational marker on Valen to show where the player currently is."
              />
            </Zoom>
          </BorderWrapper>
        </SectionWrapper>
      </Layout>
    </>
  )
}

export default WiwPage

export const Head: HeadFC = () => <Seo title={title} />
